"use client";

import { Fragment, useCallback, useState } from "react";
import { PrivatePolicyModalBody } from "./PrivatePolicyModalBody";
import { PrivatePolicyThemeUIModal } from "./PrivatePolicyThemeUIModal";

interface PrivatePolicyModalLinkProps {
  title: string;
  buttonMessage: string;
  ariaMessage?: string;
  privatePolicyURL: string;
}

export const PrivatePolicyModalLink = ({
  privatePolicyURL,
  title,
  buttonMessage,
  ariaMessage,
}: PrivatePolicyModalLinkProps) => {
  const [showPrivatePolicyModal, setShowPrivatePolicyModal] = useState(false);
  const footerOnClick = useCallback(
    () => setShowPrivatePolicyModal(!showPrivatePolicyModal),
    [showPrivatePolicyModal]
  );
  const buttonOnClick = useCallback(() => {
    setShowPrivatePolicyModal(false);
  }, []);

  const body = (
    <PrivatePolicyModalBody
      privatePolicyURL={privatePolicyURL}
      buttonMessage={buttonMessage}
      handleClose={buttonOnClick}
    />
  );

  return (
    <Fragment>
      {showPrivatePolicyModal && (
        <PrivatePolicyThemeUIModal
          showPrivatePolicyModal={showPrivatePolicyModal}
          onClose={buttonOnClick}
          ariaMessage={ariaMessage}
        >
          {body}
        </PrivatePolicyThemeUIModal>
      )}
      <div>
        <button
          onClick={footerOnClick}
          className="text-content font-sans hover:underline"
        >
          {title}
        </button>
      </div>
    </Fragment>
  );
};
