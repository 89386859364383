interface ILegalContent {
  primaryPersonLink: string;
  secondaryPersonLink: string;
}

export const LegalContent = (props: ILegalContent) => {
  return (
    <div className="mx-8 flex flex-col gap-y-4 text-p-14 sm:mx-2 sm:text-p-16">
      <div className="text-center font-bold">
        <h1 className="text-p-18 lg:text-p-24">Mentions légales</h1>
      </div>

      <div className="flex flex-col flex-wrap gap-y-4">
        <div className="flex flex-col gap-y-2">
          <h4 className="text-p-18 font-bold">Informations juridiques</h4>
          <p>
            Société éditrice : BIH, SAS au capital de 839.467€, 24, rue du
            Sentier, 75002 Paris, immatriculée au registre du commerce et des
            sociétés de Paris sous le numéro 492 854 567, et dont le numéro de
            TVA intracommunautaire est le FR18399301860.
          </p>
          <ol className="list-inside list-disc">
            <li>Téléphone : 01.44.83.83.83</li>
            <li>Email : info@businessimmo.fr</li>
            <li>Directeur de la publication : Sandra Roumi</li>
            <li>Directeur de la rédaction : Gaël Thomas</li>
          </ol>
          <p className="mt-4">
            Hébergement : CoStar Group Inc
            <ol className="list-inside list-disc">
              <li>1331 L Street, NW, Washington, DC 20005, USA</li>
              <li>N° de Telephone : (00 1) 888-226-7404</li>
            </ol>
          </p>
        </div>

        <div className="mt-4 flex flex-col gap-y-2">
          <h4 className="text-p-18 font-bold">Propriété intellectuelle</h4>
          <p>
            Business Immo reste seul propriétaire et titulaire des droits de
            propriété intellectuelle sur tous les documents et informations
            fournis sur ou par le biais du site, y compris toutes les images,
            données, illustrations, graphiques, textes, clips vidéo et audio,
            marques, logos, documents téléchargeables et autres contenus
            (collectivement appelés « Contenu de Business Immo »).
          </p>
          <p>
            Sauf autorisation écrite de Business Immo, toute reproduction ou
            représentation de tout ou partie de ce site, sur quel que support
            que ce soit, est interdite. En particulier, vous ne pouvez réaliser
            aucune des actions suivantes :
          </p>
          <ol className="list-inside list-disc">
            <li>
              Incorporer le Contenu de Business Immo dans un autre site web,
              autre œuvre ou base de données et plus généralement ré-utiliser le
              Contenu de Business Immo, par tous moyens ou procédés, y compris
              techniques dites de « framing » ou « web-scrapping » etc. ;
            </li>
            <li>
              Copier, modifier, reproduire, adapter, désassembler, distribuer,
              republier, télécharger, afficher, montrer, publier, transmettre,
              transférer, concéder sous licence ou vendre le Contenu de Business
              Immo sous quelque forme et par quelque moyen que ce soit, y
              compris notamment dans le cadre de fouilles de textes et/ou de
              données et ce quelle que soit la finalité de la fouille ;
            </li>
            <li>
              Modifier une information concernant les droits d&apos;auteur, les
              marques de commerce ou tout autre droit de propriété
              intellectuelle relatif au Contenu de Business Immo ;
            </li>
            <li>
              Utiliser le Contenu de Business Immo pour le développement de tout
              programme logiciel, y compris, mais sans s&apos;y limiter,
              l’entraînement d&apos;un système d&apos;apprentissage automatique
              ou d&apos;intelligence artificielle (IA).
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
